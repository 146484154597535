<template>
	<div class="quillEditor">
		<!-- Two-way Data-Binding -->
		<quill-editor
			class="editor ql-editor"
			ref="myQuillEditor"
			v-model="content"
			:options="editorOption"
			@blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)"
			@ready="onEditorReady($event)"
			@change="onEditorChange($event)" 
		/>  
		<el-upload
		style="display:none"
			class="upload-demo"
			accept=".jpeg,.jpg,.png,.gif,"
			action="https://user.erlangcha.com/api/file/upload?token=7ce563cc4f49544991de6ed0ac743bbe"
			:on-success="uploadImg"
			multiple>
			<el-button size="small" type="primary" :id="uniqueId">点击上传</el-button>
			<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
		</el-upload>
		<!-- <form action method="post" id="uploadFormMulti">
		<input
			style="display: none"
			:id="uniqueId"
			type="file"
			name="file"
			multiple
			@change="uploadImg('uploadFormMulti')"
		/>
		</form> -->
	</div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// 工具栏配置
const toolbarOptions = [
// 新添加的工具
//   ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
  [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ font: [] }], // 字体种类-----[{ font: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  ["clean"], // 清除文本格式-----['clean']
  ["image", "video",'link'] // 链接、图片、视频-----['link', 'image', 'video']
];

export default {
	
	// props:['uniqueId','contentData'],
	props:{
		uniqueId:String,
		contentData:String,
		pathpage:String,
	},
	data () {
		return {
			// uniqueId: "uniqueId",
			// 富文本编辑器默认内容
			content: '',
			//富文本编辑器配置
			editorOption: {
				//  富文本编辑器配置
				modules: {
				//工具栏定义的
					toolbar: toolbarOptions,
				},
				//主题
				theme: "snow",
				placeholder: "请输入正文",
			}
		}
	},
	watch:{
		contentData(){
			this.content = this.contentData
		}
	},
	computed: {
		//当前富文本实例
		editor() {
			return this.$refs.myQuillEditor.quill;
		}
	},
	components: {
		quillEditor
	},
	mounted(){
		//配置上传图片自定义接口
		setTimeout(() => {
			console.log(this.contentData)
		}, 2000);
		var _this = this;
		var imgHandler = async function(image) {
			if (image) {
				var fileInput = document.getElementById(_this.uniqueId); //隐藏的file文本ID
				fileInput.click(); //加一个触发事件
			}
		};
		this.editor.getModule("toolbar").addHandler("image", imgHandler);
		if(this.pathpage == 'moren'){
			this.content = this.contentData
		}
		
	},

	methods: {
		// 准备富文本编辑器
		onEditorReady() {},
		// 富文本编辑器 失去焦点事件
		onEditorBlur({ quill, html, text }) {
			
		},
		// 富文本编辑器 获得焦点事件
		onEditorFocus() {},
		// 富文本编辑器 内容改变事件
		onEditorChange({ quill, html, text }) {
			this.content = html
			this.$emit('getTreeData',this.content);
		},
		//图片上传成功后执行动作
		uploadImg(file){
			let url = file.data.file_url;
			if (url != null && url.length > 0) {
				let Range = this.editor.getSelection();
				//上传文件成功之后在富文本中回显(显示)
				this.editor.insertEmbed(
					Range != null ? Range.index : 0, "image", url
				);
			} else {
				this.$message.warning("图片上传失败");
			}
		}
	}
}
</script>

<style lang='scss' >
.quillEditor{
	.ql-container{
		height: calc(100% - 42px);
	}
	.ql-snow .ql-editor img{
		width: auto;
	}
	.ql-toolbar.ql-snow{
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border-color: #ededed;
	}
	.ql-container.ql-snow{
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		border-color: #ededed;
	}
	.editor {
		line-height: normal !important;
		height: 300px;
	}
		.ql-snow .ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}
		.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}

		.ql-snow .ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}

		.ql-snow .ql-picker.ql-size .ql-picker-label::before,
		.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}
		.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
		.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}
		.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
		.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}
		.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
		.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}

		.ql-snow .ql-picker.ql-header .ql-picker-label::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}
		.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}
		.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}
		.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}
		.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}
		.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}
		.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
		.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}

		.ql-snow .ql-picker.ql-font .ql-picker-label::before,
		.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}
		.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
		.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}
		.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
		.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
}

</style>
